import Layout from '@eq/components/Layout'
import animationData from '@eq/lottie/not-found.json'
import { Button } from 'antd'
import { useLottie } from 'lottie-react'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { FormattedMessage } from 'react-intl'

export default function Custom404PageContent({ layout = true, label = '', action = null as ReactNode }) {
  const router = useRouter()
  const { View } = useLottie({ animationData, loop: true, autoplay: true })
  const content = (
    <section className="py-12 px-4 text-center min-h-screen place-items-center flex -mt-28 w-full justify-center">
      <div>
        <div className="max-w-auto md:max-w-sm mx-auto">{View}</div>
        <h2 className="text-5xl mt-8 mb-8 font-heading">{label || <FormattedMessage defaultMessage="Page not found" />}</h2>
        {action || (
          <Button size="large" type="primary" ghost onClick={() => router.back()}>
            <MdArrowBack className="mdi mdi-arrow-left mr-2" />
            Go Back
          </Button>
        )}
      </div>
    </section>
  )
  if (layout) {
    return <Layout>{content}</Layout>
  }
  return <>{content}</>
}
